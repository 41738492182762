import styled from "@emotion/styled"
import { Flex, Box } from "@rebass/grid/emotion"
import { Link } from "gatsby"

import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import lockIcon from "../../../images/lock.png"

export const AccountPageBackground = styled.div`
  padding: 40px 0;
  @media (max-width: 1024px) {
    padding: 32px 0;
  }
  background: ${theme.color.beige};
  min-height: calc(100vh - 450px);
  *:not(
      a,
      button *,
      button,
      [class*="text-"],
      .action-link,
      .visual-name,
      .optional
    ) {
    color: #22202f;
  }
  label.has-value {
    color: #677574;
  }
  a {
    color: #008078;
  }
  &.loading {
    opacity: 0.25;
  }
  &.pt-0 {
    padding-top: 0;
  }
  ${queries.medium`
    min-height: calc(100vh - 290px);
  `}
  .AccountPageContainerAlt {
    padding-top: 0;
    padding-bottom: 15px;
    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
`
export const AccountPage = styled.div`
  padding: ${theme.spacing.medium} 0;
  min-height: calc(100vh - 450px);
  *:not(
      a,
      button *,
      button,
      [class*="text-"],
      .action-link,
      .visual-name,
      .optional
    ) {
    color: #22202f;
  }
  a {
    color: #008078;
  }
  &.loading {
    opacity: 0.25;
  }
  &.pt-0 {
    padding-top: 0;
  }
  ${queries.medium`
    min-height: calc(100vh - 290px);
  `}
  .AccountPageContainerAlt {
    padding-top: 0;
    padding-bottom: 15px;
    @media (min-width: 768px) {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
`
export const WithMobilePadding = styled.div`
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0;
  }
`

export const AccountPageContainer = styled.div`
  width: 100%;
  max-width: 1520px;
  padding: 32px 16px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    padding: 40px 32px;
  }
  @media (min-width: 1024px) {
    padding: 40px 40px;
  }
  @media (min-width: 1600px) {
    padding: 40px 0px;
  }
`

export const AccountPageTitleLarge = styled.h1`
  color: ${theme.wild.color.master.base.black};
  font-family: ${theme.wild.font.grilli.book};
  font-weight: ${theme.weight.light};
  font-size: 28px;
  line-height: 36px;
  ${queries.medium`
    font-size: 44px;
    line-height: 60px;
  `}
`
export const AccountTitleSmall = styled.h1`
  font-family: ${theme.wild.font.dinamo.bold};
  font-weight: ${theme.weight.demi};
  font-size: 20px;
  line-height: 28px;
  ${queries.medium`
    font-size: 24px;
    line-height: 32px;
  `}
`
export const AccountHeadingSmall = styled.h2`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: ${theme.spacing.small};
  font-family: ${theme.wild.font.grilli.book};
  font-weight: ${theme.weight.book};
  b,
  &.demi {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
  }
  ${queries.medium`
    font-size: 32px;
    line-height: 28px;
    margin-bottom: ${theme.spacing.medium};
  `}
`
export const AccountHeadingMedium = styled.h2`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: ${theme.spacing.small};
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  b,
  &.demi {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
  }
  ${queries.medium`
    font-size: 24px;
    line-height: 36px;
    margin-bottom: ${theme.spacing.medium};
  `}
`
export const AccountHeadingLarge = styled.h2`
  font-size: 18px;
  line-height: 24px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  margin-bottom: ${theme.spacing.small};
  b,
  &.demi {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
  }
  ${queries.medium`
    font-family: ${theme.wild.font.grilli.book};
    font-weight: ${theme.weight.light};
    font-size: 24px;
    line-height: 32px;
    margin-bottom: ${theme.spacing.medium};
  `}
`
export const AccountHeadingLargeNeue = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.regular};
  margin: 32px 0;
  text-align: left;

  &.c-small-pad {
    margin: 40px 0 8px 0;
  }

  span {
    font-style: italic;
    font-size: 14px;
    color: #737273;
  }

  ${queries.medium`
    margin: 40px 0;
    font-size: 28px;
    line-height: 37px;
  `}
`
export const AccountHeadingMediumNeue = styled.h2`
  font-size: 16px;
  line-height: 24px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.regular};
  margin: 0;
  text-align: left;

  span {
    font-style: italic;
    font-size: 14px;
    color: #737273;
  }

  strong {
    background: linear-gradient(to top, #dce6dd 50%, transparent 50%);
  }

  ${queries.medium`
    margin: 0;
    font-size: 20px;
    line-height: 26px;
  `}
`

export const AccountHeadingXXLarge = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  margin-bottom: ${theme.spacing.small};
  b,
  &.demi {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
  }
  ${queries.medium`
    font-family: ${theme.wild.font.grilli.book};
    font-weight: ${theme.weight.light};
    font-size: 32px;
    line-height: 44px;
    margin-bottom: ${theme.spacing.medium};
  `}
`
export const AccountCard = styled.div`
  background: ${theme.color.white};
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 40px ${theme.spacing.medium};
  height: 100%;
  &.secondary {
    background: #dce6dd;
  }
  &.transparent {
    background: transparent;
    box-shadow: none;
  }
  &.height-auto {
    height: auto;
  }
  ${queries.medium`
    .c-right {
     text-align: right;
    }
    padding: ${theme.spacing.large} ${theme.spacing.medium};
    &.padded-sm {
      padding: ${theme.spacing.medium};
    }
    &.padded-lg {
      padding: 50px 35px;
    }
  `}
  ${queries.large`
    &.padded-lg {
      padding: 50px 65px;
    }
  `}
  .c-canceled {
    font-family: ${theme.wild.font.dinamo.regular};
  }
`
export const AccountCardHeading = styled.h4`
  font-family: ${theme.wild.font.dinamo.bold};
  font-weight: ${theme.weight.demi};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: ${theme.spacing.small};
  color: ${theme.wild.color.master.base.black};
  &.mb-0 {
    margin-bottom: 0;
  }
  ${queries.medium`
    font-size: 24px;
    line-height: 32px;
    margin-bottom: ${theme.spacing.medium};
  `}
`
export const GreenLink = styled.button`
  font-family: ${theme.wild.font.dinamo.mono};
  font-weight: ${theme.weight.mono};
  text-decoration: none;
  border-bottom: 1px solid ${theme.wild.color.master.primary.default};
  color: ${theme.wild.color.master.primary.default};
  font-size: 12px;
  line-height: 130%;
  ${queries.medium`
  font-size: 14px;
  `}
  ${queries.large`
  font-size: 16px;
  `}
  &.c-schedule {
    min-width: 156px;
  }
`
export const GreenLinkArrow = styled.button`
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.mono};
  border-bottom: none;
  color: ${theme.wild.color.master.primary.default};
  font-size: 14px;
  line-height: 18px;
  position: relative;
  transform: translate(20px);
  &:before {
    content: "<";
    position: absolute;
    transform: scale(1.3, 1.6) translate(-12px, 0.5px);
  }
`

export const GrayLine = styled(Box)`
  height: 1px;
  width: 100%;
  background: ${theme.color.lightGray};
  &.light {
    background: rgba(34, 32, 47, 0.3);
  }
  &.lighter {
    background: #e4e4e4;
  }
`
export const TextSmall = styled.p`
  font-size: 14px;
  line-height: 18px;
  color: ${theme.wild.color.master.base.black};
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  b,
  &.demi {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
  }
`
export const TextMedium = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: ${theme.wild.color.master.base.black};
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  b,
  &.demi {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
  }
`

export const CardLink = styled.a`
  color: rgb(31, 99, 96);
  font-size: 14px;
  font-family: ${theme.wild.font.dinamo.bold};
  font-weight: ${theme.weight.demi};
  letter-spacing: 0px;
`

export const MonoHeading = styled.h3`
  color: rgb(34, 32, 47);
  font-size: 12px;
  font-family: ${theme.wild.font.dinamo.mono};
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  line-height: 18px;
  &.light {
    color: ${theme.color.lightGray};
  }
`
export const GreenGatsbyLink = styled(Link)`
  font-family: ${theme.wild.font.dinamo.bold};
  font-weight: ${theme.weight.demi};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.wild.color.master.primary.default};
  &.large {
    font-size: 13px;
    line-height: 16px;
  }
  span {
    font-family: ${theme.wild.font.dinamo.bold};
    font-weight: ${theme.weight.demi};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.wild.color.master.primary.default};
  }
  &.light {
    color: ${theme.color.lightGray};
  }
`

export const GreenLinkButton = styled.button`
  font-family: ${theme.wild.font.dinamo.bold};
  font-weight: ${theme.weight.demi};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: ${theme.wild.color.master.primary.default};
  &.light {
    color: ${theme.color.lightGray};
  }
  &.default_cursor {
    cursor: default;
  }
  &.g-disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`

export const GreenLinkButtonMono = styled.button`
  font-family: ${theme.wild.font.dinamo.mono};
  font-weight: ${theme.weight.mono};
  font-size: 14px;
  line-height: 18px;
  color: ${theme.wild.color.master.primary.default};
  border-bottom: 1px solid ${theme.wild.color.master.primary.default};
`

export const BookParagraph = styled(Flex)`
  color: rgb(34, 32, 47);
  font-size: 14px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: 300;
  letter-spacing: 0;
`

export const ModalHeading = styled.div`
  color: rgb(34, 32, 47);
  font-size: 22px;
  line-height: 30px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  letter-spacing: 0px;
  margin: 0 0 ${theme.spacing.medium} 0;
  .mobile-br {
    display: block;
    ${queries.medium`
      display: inline;
    `}
  }
  ${queries.medium`
    font-family: ${theme.wild.font.grilli.book};
    font-weight: ${theme.weight.light};
    font-size: 28px;
    line-height: 36px;
  `}
`

export const SubcriptionCheckboxesWrap = styled.div`
  //border-top: 1px #ccc solid;
  padding: 15px 0 0 0;
  .InputCheckbox {
    span {
      font-size: 14px;
    }
  }
`

export const ProductWrap = styled.div`
  &.canceled {
    padding: ${theme.spacing.small};
    position: relative;
    @media (min-width: 768px) {
      padding: ${theme.spacing.medium};
      margin-top: ${theme.spacing.small};
    }
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.6);
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23737273FF' stroke-width='2' stroke-dasharray='2%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      z-index: 1;
      pointer-events: none;
    }
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 10px;
      height: 16px;
      width: 13px;
      background-image: url(${lockIcon});
      background-size: 13px 16px;
      z-index: 2;
    }
  }
  @media (min-width: 1024px) {
    margin-top: 11px;
  }
`

export const ModalFormHeading = styled.div`
  color: ${theme.wild.color.master.base.black};
  font-size: 28px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: ${theme.weight.book};
  letter-spacing: 0px;
  line-height: 36px;
  margin: 0 0 ${theme.spacing.medium} 0;
`
export const Heading = styled.div`
  color: rgb(34, 32, 47);
  font-size: 28px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 37px;
  margin: 0 0 24px 0;

  &.c-center {
    text-align: center;
  }
`
export const SubWrap = styled.div`
  //border-top: 1px #ccc solid;
  padding: 0;

  .InputCheckboxWild {
    span {
      font-size: 14px;
    }
  }

  input {
    cursor: pointer;
  }

  .mono {
    margin: 15px 0;
  }

  .c-readonly {
    input {
      opacity: 0.3 !important;
    }
  }
`

export const Text = styled.div`
  color: rgb(34, 32, 47);
  font-size: 16px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: 300;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
`

export const ReactivateWrap = styled.div`
  color: rgb(34, 32, 47);
  font-size: 16px;
  font-family: ${theme.wild.font.dinamo.regular};
  font-weight: 300;
  text-align: center;
  letter-spacing: 0;
  line-height: 24px;
  padding: 16px 0 0;
  border-top: 2px #ddd solid;
  * {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
  }
`

export const DualModalWrapper = styled.div`
  .c-btn {
    margin: 30px auto 0;
    display: block;
  }

  &.hide {
    display: none;
  }

  .slick-list {
    transition: height 250ms linear;
  }

  // safari fix https://github.com/kenwheeler/slick/issues/1868
  .slick-slide {
    padding-left: 1px;
    padding-right: 1px;
  }

  *,
  *:focus {
    outline: none !important;
  }
`

export const CoreImageBox = styled(Box)`
  display: flex;
  align-items: flex-end;
  img {
    width: 65%;
    transform: translate(0);
    @media (min-width: 768px) {
    }
  }
`

export const BoosterProductContainer = styled(Flex)`
  height: 100%;
  align-items: flex-end;
`

export const BoosterProductWrap = styled(Box)`
  /* align-items: stretch;
  img {
    margin-left: -5px;
    max-height: 240px;
    @media (min-width: 768px) {
      margin-left: -10px;
    }
  } */
`

export const BoosterUpgradeWrap = styled(Box)``

export const EmptyBoosterProduct = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23737273FF' stroke-width='2' stroke-dasharray='2%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  min-width: 100%;
  min-height: 20vw;
  cursor: pointer;
  position: relative;
  @media (min-width: 768px) {
    min-height: 14vw;
  }
  @media (min-width: 1200px) {
    min-height: 230px;
  }
  &.canceled {
    cursor: default;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const UpgradeItem = styled.button`
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 16px 0;
  height: 340px;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, #f9f6f2 53%, #fff 53%);

  .c-name {
    font-family: ${theme.wild.font.dinamo.regular};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0;
    text-align: left;
    margin: 0;
    @media (min-width: 768px) {
      margin: 24px 0 4px 0;
      font-size: 16px;
    }
  }

  &.c-booster {
    background: linear-gradient(to bottom, #f9f6f2 57%, #fff 57%);
    .c-name {
      font-size: 11px;
      @media (min-width: 360px) {
        font-size: 13px;
        @media (min-width: 768px) {
          font-size: 15px;
          @media (min-width: 850px) {
            font-size: 16px;
          }
        }
      }
    }
    @media (min-width: 768px) {
      width: 90%;
    }
    &.can-select-0 {
      opacity: 0.5;
      cursor: default;
    }
  }
  border: 2px solid #e4e4e4;
  box-shadow: 0 3.03836px 15.1918px rgba(31, 99, 96, 0.08);
  border-radius: 6px;
  @media (min-width: 768px) {
    height: 416px;
  }
  * {
    text-align: left;
  }
  .c-learnmore {
    font-size: 11px;
    @media (min-width: 768px) {
      font-size: 12px;
    }
  }
  .c-check {
    position: absolute;
    top: -18px;
    right: -18px;
  }
  .c-active {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 16px;
    width: 60%;
    font-size: 10px;
    min-width: 115px;
    @media (min-width: 960px) {
      //left: 42px;
    }
    padding: 2px 4px;
    @media (min-width: 400px) {
      min-width: 134px;
      font-size: 11px;
      padding: 2px 10px;
    }
    background: #eee8de;
    border-radius: 50px;
    font-family: ${theme.wild.font.dinamo.regular};

    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
  .c-holder {
    padding: 0 8px 0 12px;
    width: 100%;
    // @media (min-width: 300px) {
    //   transform: translate(0, 25px);
    // }
    // @media (min-width: 480px) {
    //   transform: translate(0);
    // }
    // @media (min-width: 768px) {
    //   transform: translate(0, 16px);
    // }
    // @media (min-width: 960px) {
    //   transform: translate(0);
    //   padding: 0 20px 0 24px;
    // }
    margin-top: auto;
    margin-bottom: 12px;
  }

  .c-holder-booster {
    width: 100%;
    padding: 0 8px 0 12px;
    // transform: translate(0px, 36px);
    // @media (min-width: 400px) {
    //   transform: translate(0px, 30px);
    // }
    // @media (min-width: 960px) {
    //   padding: 0 24px;
    // }
    // @media (min-width: 1024px) {
    margin-top: auto;
    margin-bottom: 12px;
    // }
  }
  .c-image {
    min-width: auto;
    transform: scale(0.87);
    @media (min-width: 768px) {
      min-width: 155px;
      transform: scale(0.95);
    }
    @media (min-width: 960px) {
      min-width: 185px;
    }
  }
  .c-image-booster {
    width: auto;
    transform: none;
    @media (min-width: 768px) {
      transform: none;
    }
  }
  .c-price {
    font-family: ${theme.wild.font.dinamo.regular};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin: 8px 0;
    @media (min-width: 768px) {
      font-size: 16px;
      margin: 16px 0;
    }
  }
  .c-subtext {
    font-family: ${theme.wild.font.dinamo.regular};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
    &.c-min-h {
      min-height: 42px;
    }
    @media (min-width: 768px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &.is-selected {
    opacity: 1;
    border: 2px solid #008078;
  }
`

export const UpgradeItemWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  &.c-selected {
    > div > button {
      opacity: 0.5;
    }
    > div > button.is-selected {
      opacity: 1;
    }
  }
`

export const UpgradeBoosterWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  transform: scale(1.02) translate(1.7%, 0);
  &.c-selected {
    > div > button {
      opacity: 0.5;
    }
    > div > button.is-selected {
      opacity: 1;
    }
  }
`

export const UpgradeWrapper = styled.div`
  padding: 0 25px;
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    max-width: 1520px;
  }
  button {
    &.primary {
      width: 208px;
    }
    &.disabled {
      background: #e4e4e4;
      border-color: #e4e4e4;
      color: #454545;
    }
  }
  section {
    max-width: 480px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 768px) {
      max-width: 1168px;
    }
  }
`
export const ReferencesContainer = styled.div`
  text-align: center;
  padding: ${theme.spacing.medium};
  font-size: 10px;
  color: rgb(116, 114, 115);
  @media (min-width: 768px) {
    font-size: 12px;
    text-align: center;
  }
`

export const ProfileCard = styled(Flex)`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  @media (min-width: 360px) {
    padding: 16px;
  }
  @media (min-width: 1024px) {
    padding: 64px 64px;
    flex: 1;
  }
`

export const FormWrapper = styled.div`
  width: 100%;
  label {
    cursor: pointer;
  }

  .c-CloseWrapper {
    display: none;
  }

  .c-btn-submit {
    margin: 0 32px 0 0;
  }
  .btn {
    text-transform: none;
    width: 100%;
  }
  .secondField {
    @media (min-width: 768px) {
      margin-left: 16px;
    }
  }
  .inputProfile {
    margin-bottom: 8px;
    color: #677574;
  }
`

export const ButtonsWrapper = styled(Box)`
  width: 100%;
  * {
    text-align: center;
  }
  .btn {
    width: 100%;
    text-transform: none;
  }
`

export const MobileModalFooter = styled(Box)`
  @media screen and (max-width: 768px) {
    background: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
  }
`
